.App {
  text-align: center;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid black;
  background-color: white;
  cursor: pointer;
  position: relative;
}

input[type="checkbox"]:checked {
  background-color: black;
}

input[type="checkbox"]::before {
  content: "\2714";
  font-size: 14px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body {
  margin: 0;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(192, 192, 192);
}
.scroller {
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-color: rgb(192, 192, 192);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-corner {
  background: transparent;
}

/* *::-webkit-scrollbar-track { */
/* box-shadow: inset 0 0 2px rgba(112, 112, 112, 0.3); */
/* } */

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: darkgrey;
  /* outline: 1px solid slategrey; */
}
.overflow-custom-div {
  overflow: hidden;
}

.overflow-custom-div:hover {
  overflow-y: scroll;
}

.overflow-custom-div-x {
  overflow: hidden;
}

.overflow-custom-div-x:hover {
  overflow-x: auto;
  overflow-y: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
